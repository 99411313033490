import defaultProfilePicture from "../images/defaultProfilePicture.png";

export const defaults = {
    applicationName: "applicationName",
    engineRevTheme: "engineRev",
    lightTheme: "light",
    greyTheme: "grey",
    darkTheme: "dark",
    redTheme: "red",
    orangeTheme: "orange",
    yellowTheme: "yellow",
    greenTheme: "green",
    blueTheme: "blue",
    lavenderTheme: "lavender",
    violetTheme: "violet",
    threadCategoryDropdown: "threadCategoryDropdown",
    themeSelectionDropdown: "themeSelectionDropdown",
    sortThreadsDropdown: "sortThreadsDropdown",
    transparentLightTheme: "transparentLight",
    transparentGreyTheme: "transparentGrey",
    transparentDarkTheme: "transparentDark",
    transparentRedTheme: "transparentRed",
    transparentOrangeTheme: "transparentOrange",
    transparentYellowTheme: "transparentYellow",
    transparentGreenTheme: "transparentGreen",
    transparentBlueTheme: "transparentBlue",
    transparentLavenderTheme: "transparentLavender",
    transparentVioletTheme: "transparentViolet",
    newBackgroundPictureInput: "newBackgroundPictureInput",
    newProfilePictureInput: "newProfilePictureInput",
    newDisplayNameInput: "newDisplayNameInput",
    defaultThreadCategory: "Other",
    defaultProfilePicture: defaultProfilePicture,
    defaultDisplayName: "EngineRev User",
    loggedOutStartThread: "Please sign in or create an account to start a thread.",
    noCommentsOnThread: "Be the first to add a comment!",
    threadDetailsCannotBeBlank: "Title and description are required.",
    areYouSureToDeleteThread: "Are you sure you want to delete this thread? All associated comments will also be deleted.",
    areYouSureToDeleteThreadComment: "Delete this comment?",
    threadDetailsUpdated: "Thread successfully updated.",
    alreadyVotedOnComment: "You have already voted on this comment.",
    addThreadCommentSucess: "Comment posted successfully.",
    deleteThreadCommentSucess: "Comment deleted successfully.",
    deleteVehicleSucess: "Vehicle deleted successfully.",
    threadCommentsCannotBeBlank: "Comments cannot be blank.",
    updateThreadCommentSucess: "Comment updated successfully.",
    noAuthorizationToPerformAction: "You are not authorized to perform this action.",
    noAuthorizationToViewPage: "You do not have permission to view this content.",
    deleteOneReleaseNoteSuccess: "Release note deleted successfully.",
    updateOneReleaseNoteSuccess: "Release note updated successfully.",
    addOneReleaseNoteSuccess: "Release note added successfully.",
    invalidInputDetected: "Invalid input detected.",
    resetInputFieldError: "Unable to reset input field.",
    addThreadSuccessfully: "Thread created successfully.",
    vehicleNameUpdatedSuccessfully: "Vehicle name updated successfully.",
    serviceLogDeletedSuccessfully: "Service log deleted successfully.",
    passwordUpdatedSuccessfully: "Password updated successfully.",
    passwordsDoNotMatch: "Passwords do not match.",
    errorLoadingVehicleCount: "Error loading vehicle count.",
    passwordConfirmationSent: "Password confirmation sent. Please check your email.",
    inputFieldsReset: "Input fields reset.",
    inputFieldReset: "Input field reset.",
    emailBlankError: "Email cannot be blank.",
    emailUpdatedSuccessfully: "Email updated successfully.",
    passwordBlankError: "Password cannot be blank.",
    adminRole: "Administrator",
    userRole: "User",
    testUserRole: "Test User",
    commentsSection: "commentsSection",
    oldestThreadsSort: "oldestThreadsSort",
    mostRecentThreadsSort: "mostRecentThreadsSort",
    mostViewsThreadsSort: "mostViewsThreadsSort",
    askCarQuestionsThreadsSort: "askCarQuestionsThreadsSort",
    tipsAndTricksThreadsSort: "tipsAndTricksThreadsSort",
    shareStoryThreadsSort: "shareStoryThreadsSort",
    otherCategoryThreadsSort: "otherCategoryThreadsSort",
    updatedFutureDateServiceLog: "is a future date. Proceed with this entry?",
    displayNameLengthNotMet: "Display name must be 6 characters or more.",
    displayNameAlreadyExists: "Display name already in use.",
    displayNameUpdatedSuccessfully: "Display name updated successfully.",
    deleteVehicleWarning: "You are about to delete this vehicle and any service logs associated with it. " +
        "Are you sure you want to continue?",
    noSortResults: "No Results for Sort Criteria",
    aboutEngineRev: "EngineRev allows you to keep track of your vehicle maintenance history and collaborate with the community. " +
        "Keep your vehicle running smoothly and meet other passionate do-it-yourselfers in the forum. " +
        "Ask questions and share vehicle diagnostic solutions with the community.",
    whoIsEngineRevFor: "EngineRev is for anyone, mechanically inclined or not, who want to keep track of their vehicle service maintenance history. " +
        "Users can create their own accounts and add vehicles they want to keep track of. They can also update their " +
        "account information along with updating vehicle and service log information. " +
        "Service logs are tracked by adding the date of service, the mileage of the vehicle, and the type of service performed on the vehicle. " +
        "Have a car repair question or want to help someone else out? Head to the forums to see what others are talking about."
};
