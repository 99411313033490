export const events = {
    eventSuccess: "Success",
    eventError: "Error",
    eventNoErrors: "No errors.",
    addedNewVehicle: "Add a vehicle.",
    deletedVehicle: "Delete a vehicle.",
    updateOneServiceLog: "Update a service log.",
    deleteOneServiceLog: "Delete a service log.",
    addOneServiceLog: "Add a service log.",
    updateVehicleInformation: "Update vehicle information.",
    saveTheme: "Save theme.",
    updateDisplayName: "Update display name.",
    updateBackgroundPicture: "Update background picture.",
    updateProfilePicture: "Update profile picture.",
    updateEmail: "Update Email.",
    updatePassword: "Update password.",
    addOneThread: "Add thread.",
    addCommentToThread: "Add a comment to a thread.",
    updateThreadDetails: "Update the thread title and/or description.",
    deleteThread: "Delete a thread.",
    upvoteComment: "Upvote a comment.",
    downvoteComment: "Downvote a comment.",
    deleteThreadComment: "Delete a comment from a thread.",
    updateThreadComment: "Update a comment to a thread."
};
